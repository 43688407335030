














































































































































































































import PacienteModule from "@/store/modules/paciente-module";
import { Component, Vue } from "vue-property-decorator";
import { paciente } from "@/shared/dtos/paciente-dto";
import { UtilsString } from "@/utils/utils-string";
import { RouterNames } from "@/router/routernames";
import { UtilsPaciente } from "@/utils/utils-paciente";
import { UtilsEncript } from "@/utils/utils-encript";
import etiquetaModule from "@/store/modules/etiqueta-module";
import { ssmMessageService } from "@/shared/services/message-service";

@Component({
  components: {
    AddCitaPaciente: () => import("@/views/calendario/AddCitaPaciente.vue"),
    EnvioCamapanaMarketing: () =>
      import("@/views/etiquetas_agrupacion/envio_campaña_marketing.vue"),
  },
})
export default class PacienteLista extends Vue {
  //Se ejecuta nada mas entrar
  public buscar_paciente: string = "";
  public pacientes: paciente[] = [];
  public defaultpaci: number = -1;
  public loadingtextfield: boolean = false;
  public totalpaci: number = 0;
  public textototal: string = "";
  public AddCitaShow: boolean = false;
  public MarketingShow: boolean = false;
  public fecha_cita: Date = new Date();
  public etiquetas_seleccionadas: string[] = [];
  public created() {
    this.Refrescar();
    etiquetaModule.getetiquetas_from_tipo(2);
  }
  public get Etiquetas() {
    return etiquetaModule.etiquetas_for_vuetify;
  }

  public get datapacientes() {
    let result_prefiltro_pacientes: paciente[] = [];
    if (this.etiquetas_seleccionadas.length !== 0) {
      for (let index = 0; index < PacienteModule.pacientes.length; index++) {
        var element_index = PacienteModule.pacientes[index];
        for (
          let index2 = 0;
          index2 < element_index.Pac_EtiDto.length;
          index2++
        ) {
          var element_index2 = element_index.Pac_EtiDto[index2];
          var res = this.etiquetas_seleccionadas.filter(
            (etiqueta_seleccionada) =>
              Number.parseInt(etiqueta_seleccionada) ===
              Number.parseInt(
                UtilsString.ValueOfValue(element_index2.id_etiqueta)
              )
          );
          if (res.length > 0) {
            result_prefiltro_pacientes.push(element_index);
          }
        }
      }
    } else {
      result_prefiltro_pacientes = PacienteModule.pacientes;
    }
    if (this.buscar_paciente === "") {
      this.totalpaci = result_prefiltro_pacientes.length;
      this.textototal = "";
      return result_prefiltro_pacientes;
    }

    this.loadingtextfield = true;
    this.pacientes = [];
    for (let i = 0; i < result_prefiltro_pacientes.length; i++) {
      const paciente: paciente = result_prefiltro_pacientes[i];
      if (
        UtilsString.eliminarDiacriticosEs(paciente.nombre)
          .toLowerCase()
          .includes(
            UtilsString.eliminarDiacriticosEs(
              this.buscar_paciente
            ).toLowerCase()
          ) ||
        UtilsString.eliminarDiacriticosEs(paciente.apellidos)
          .toLowerCase()
          .includes(
            UtilsString.eliminarDiacriticosEs(
              this.buscar_paciente
            ).toLowerCase()
          ) ||
        UtilsString.eliminarDiacriticosEs(paciente.email)
          .toLowerCase()
          .includes(
            UtilsString.eliminarDiacriticosEs(
              this.buscar_paciente
            ).toLowerCase()
          ) ||
        UtilsString.eliminarDiacriticosEs(
          UtilsString.ValueOf(paciente.telefono1)
        )
          .toLowerCase()
          .includes(
            UtilsString.eliminarDiacriticosEs(
              this.buscar_paciente
            ).toLowerCase()
          ) ||
        UtilsString.eliminarDiacriticosEs(
          UtilsString.ValueOf(paciente.numero)
        )
          .toLowerCase()
          .includes(
            UtilsString.eliminarDiacriticosEs(
              this.buscar_paciente
            ).toLowerCase()
          )
      ) {
        this.pacientes.push(paciente);
      }
    }
    this.totalpaci = this.pacientes.length;
    this.textototal = " en la busqueda ";
    this.loadingtextfield = false;
    return this.pacientes;
  }
  //
  public get label_buscar() {
    if (this.buscar_paciente === "") {
      return this.$i18n.tc("buscar");
    }
    return "";
  }
  public Refrescar() {
    PacienteModule.getpacientes();
  }
  public ImgPaciente(paciente: paciente) {
    return UtilsPaciente.getrutaImagen(paciente);
  }
  public nuevo_paci() {
    this.$router.push({ name: RouterNames.pacientesedit });
  }
  public seguimiento_paci(paciente: paciente) {
    this.$router.push({
      name: RouterNames.seguimiento_paciente,
      query: {
        id_paciente: UtilsEncript.Encriptar(paciente.numero.toString()),
      },
    });
  }

  public edit_paci(paciente: paciente) {
    this.$router.push({
      name: RouterNames.pacientesedit,
      query: { id: UtilsEncript.Encriptar(paciente.numero.toString()) },
    });
  }
  public nueva_cita_paci(paciente: paciente) {
    this.fecha_cita = new Date();
    this.defaultpaci = paciente.numero;
    this.AddCitaShow = true;
  }
  public iniciar_consulta(paciente: paciente) {
    this.$router.push({
      name: RouterNames.entrevista,
      query: {
        id_paciente: UtilsEncript.Encriptar(paciente.numero.toString()),
      },
    });
  }
  public enviar_mail(paciente: paciente) {
    if (UtilsString.IsNullOrWhiteSpace(UtilsString.ValueOf(paciente.email))) {
      ssmMessageService.toast(
        "No tiene email definido",
        ssmMessageService.TypeWarning
      );
      return;
    }
    this.$router.push({
      name: RouterNames.sendemailpaciente,
      query: {
        id: UtilsEncript.Encriptar(paciente.numero.toString()),
      },
    });
  }
}
